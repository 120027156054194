<template>
  <SectionY :padding="data.padding?.value">
    <h2
      v-if="data.heading"
      class="default-component-title mx-auto mb-14 xl:mb-20"
    >
      <span v-html="$replaceHtmlTags(data.heading)" />
    </h2>

    <div class="space-y-container-sm xl:space-y-container-xl">
      <PartnerLogoRow
        v-for="(row, index) in data.partner_logo_rows"
        :key="index"
        :data="row"
        :index="index"
      />
    </div>
  </SectionY>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
